import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children: 'https://xgkb.mystery0.vip/images/screenshot/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: 'https://github.com/Mystery00/XhuTimetable',
          children: [{ children: 'Android端源码', name: 'text' }],
          target: '_blank',
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: 'https://github.com/Mystery00/xhu_timetable_ios',
          children: [{ children: 'iOS端源码', name: 'text' }],
          target: '_blank',
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: 'https://blog.mystery0.vip/xgkb-sponsor/index.html',
          children: [{ children: '赞助', name: 'text' }],
          target: '_blank',
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>查课查成绩</p>
            <p>就用西瓜课表</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>由西瓜课表团队精心制作</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <p>
                西瓜课表是一款便捷而强大的学习助手，旨在帮助学生高效地管理和规划他们的学习时间。无论是查课表、查询成绩还是安排学习计划，西瓜课表都能轻松应对。它提供直观易用的界面，让您快速浏览和安排课程表，轻松管理学业。
              </p>
              <p>
                利用西瓜课表，您可以随时随地查看您的课程安排，快速了解每天的学习计划。不再为错过重要课程而犯愁，也不会因为混乱的时间安排而感到束手无策。此外，通过西瓜课表，您可以轻松查询自己的成绩，掌握自己的学习进展。<br />
              </p>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://xgkb.api.mystery0.vip/api/rest/web/version/latest',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>下载西瓜课表</p>
            </span>
          ),
        },
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://apps.apple.com/us/app/%E8%A5%BF%E7%93%9C%E8%AF%BE%E8%A1%A8/id6497878324',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>前往AppStore下载</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://xgkb.mystery0.vip/images/screenshot/banner.png',
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>不止能查课表，还能做其他事情</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://xgkb.mystery0.vip/images/screenshot/function.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://xgkb.mystery0.vip/images/action/action_course.svg',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>
                  <b>教务数据查询</b>
                </p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>自动从教务系统同步数据，随时随地查询课表、考试、成绩信息</p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://xgkb.mystery0.vip/images/action/action_account.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <b>多账号登录</b>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>同时登录多个账号，可以一次性查看完整课表</p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children: 'https://xgkb.mystery0.vip/images/action/action_thing.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>
                <b>自定义课程&amp;事项</b>
              </p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>自由安排空闲时间，可以蹭课，也可以设置倒计时</p>
            </span>
          ),
        },
      },
      {
        name: 'block3',
        img: {
          className: 'content6-icon',
          children:
            'https://xgkb.mystery0.vip/images/action/action_classroom.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <p>
                    <b>空闲教室查询</b>
                  </p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>随时随地查看指定时间段的空闲教室列表</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: '服务指标' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>天+</p>
                  </span>
                ),
              },
              toText: true,
              children: '2570',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>持续运行</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>万+</p>
                  </span>
                ),
              },
              toText: true,
              children: '=18820',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>累计提供请求次数</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>人+</p>
                  </span>
                ),
              },
              toText: true,
              children: '107867',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>累计服务人数</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>感谢大家这些年来的支持！</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>西瓜课表团队不会摒弃初心，</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>会持续为大家带来更多的服务。</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Teams20DataSource = {
  wrapper: { className: 'home-page-wrapper teams2-wrapper' },
  page: { className: 'home-page teams2' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '团队成员' }],
  },
  block: {
    className: 'block-wrapper',
    gutter: 72,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://xgkb.mystery0.vip/images/team/img_yue.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>爱吃饭的越越</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <span>
                    <p>图标设计与美术</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>西华大学2016级计算机学院学生</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://xgkb.mystery0.vip/images/team/img_pan.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>pan</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <span>
                    <p>数据服务</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>西华大学2015级计算机学院学生</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://xgkb.mystery0.vip/images/team/img_johnny.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>Johnny Zen</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <span>
                    <p>产品体验与市场</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>西华大学2015级计算机学院学生</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://xgkb.mystery0.vip/images/team/img_quinn.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>Quinn</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <p>UI设计师、算法、全栈工程师</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>西华大学2014级计算机学院学生</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://xgkb.mystery0.vip/images/team/img_mystery0.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <p>Mystery0</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <p>Android/iOS开发工程师/全干工程师</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>西华大学2015级计算机学院学生</p>
                </span>
              ),
            },
          ],
        },
      },
      // {
      //   name: 'block5',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   image: {
      //     name: 'image',
      //     className: 'teams2-image',
      //     children: 'https://xgkb.mystery0.vip/images/team/img_chen.png',
      //   },
      //   titleWrapper: {
      //     className: 'teams2-textWrapper',
      //     children: [
      //       {
      //         name: 'title',
      //         className: 'teams2-title',
      //         children: (
      //           <span>
      //             <p>Chen</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams2-job',
      //         children: (
      //           <span>
      //             <p>iOS开发工程师</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams2-content',
      //         children: (
      //           <span>
      //             <p>西华大学2015级计算机学院学生</p>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        © Copyright © 2017-2024<span>&nbsp;All Rights Reserved</span>
      </span>
    ),
  },
};
